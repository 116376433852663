import Installation from '@/views/Installation/Installation.vue';

export default [
  {
    path: '/installation',
    meta: {
      breadcrumb: () => `Installations`
    },
    component: () => import('@/components/Base/ParentComponent.vue'),
    children: [
      {
        path: '',
        component: Installation,
      },
      {
        path: 's',
        component: Installation,
        props: true,
        children: [
          {
            path: ':installationName',
            props: route => ({ query: route.query.type }),
            component: Installation,
          },
        ]
      },
      {
        path: ':installationId',
        meta: {
          breadcrumb: routeParams => routeParams.installationId
        },
        props: true,
        component: () => import('@/views/Installation/InstallationDetails.vue'),
        children: [
          {
            path: '',
            props: true,
            redirect: { path: '/installation/:installationId/operation' },
          },
          {
            path: 'operation',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Operation.vue'),
            meta: {
              breadcrumb: `Operations`
            },
            children: [
              {
                path: ':noteId',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_Operation.vue')
              },
              {
                path: 'q/:skicon',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_Operation.vue')
              }
            ]
          },
          {
            path: 'freshdesk',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Freshdesk.vue'),
            meta: {
              breadcrumb: `Freshdesk`
            }
          },
          {
            path: 'session',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Session.vue'),
            meta: {
              breadcrumb: `Sessions`
            }
          },
          {
            path: 'detail',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Detail.vue'),
            meta: {
              breadcrumb: `Details`
            }
          },
          {
            path: 'devices',
            meta: {
              breadcrumb: `Devices`
            },
            props: true,
            component: () => import('@/components/Base/ParentComponent.vue'),
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_Devices.vue'),
              },
              {
                path: 'ipscanner',
                props: true,
                component: () => import('@/views/IpScannerView.vue'),
                meta: {
                  breadcrumb: `Ip Scanner`
                },
                children: [
                  {
                    path: ':laneNumber',
                    props: true,
                    component: () => import('@/views/IpScannerView.vue'),
                  }
                ]
              },
              {
                path: 'device',
                props: true,
                component: () => import('@/views/Devices/DeviceParentView.vue'),
                meta: {
                  breadcrumb: 'Device'
                },
                children: [
                  {
                    path: '',
                    props: true,
                    redirect: { path: '/installation/:installationId/devices' },
                  },
                  {
                    path: ':deviceId',
                    props: true,
                    component: () => import('@/views/Devices/DeviceView.vue'),
                    meta: {
                      breadcrumb: routeParams => routeParams.deviceId
                    }
                  },
                  {
                    path: ':deviceId/:conf',
                    props: true,
                    component: () => import('@/views/Devices/DeviceView.vue'),
                    meta: {
                      breadcrumb: routeParams => routeParams.deviceId
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'eventmovie',
            component: () => import('@/views/EventMovieView.vue'),
            props: true,
            meta: {
              breadcrumb: `Event Movie`
            },
          },
          {
            path: 'installation-ips',
            component: () => import('@/views/Installation/InstallationDetail_InstallationIp.vue'),
            props: true,
            meta: {
              breadcrumb: `IP Whitelist`,
            },
          },
          {
            path: 'eventmovie/configure',
            component: () => import('@/views/EventMovieConfigurationView.vue'),
            props: true,
            meta: {
              breadcrumb: `Event Movie Configuration`
            },
          },
          {
            path: 'trackerv3',
            component: () => import('@/components/Base/ParentComponent.vue'),
            meta: {
              breadcrumb: `Tracker V3`
            },
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_TrackerV3.vue'),
              }
            ]
          },
          {
            path: 'tracker',
            component: () => import('@/components/Base/ParentComponent.vue'),
            meta: {
              breadcrumb: `Tracker V4`
            },
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_Tracker.vue'),
              },
              {
                path: 'videopublisher',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Video Publisher`
                },
                children: [
                  {
                    path: '',
                    props: true,
                    component: () => import('@/views/VideoPublisherView.vue')
                  },
                  {
                    path: 'lane/:laneNumber',
                    props: true,
                    component: () => import('@/views/VideoPublisherView.vue')
                  }
                ]
              },
              {
                path: 'config',
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Configuration`
                },
                children: [
                  {
                    path: '',
                    redirect: { path: '/installation/:installationId/tracker' },
                  },
                  {
                    path: 'cameramanagement',
                    props: true,
                    component: () => import('@/components/Base/ParentComponent.vue'),
                    meta: {
                      breadcrumb: `Camera Management`
                    },
                    children: [
                      {
                        path: '',
                        props: true,
                        component: () => import('@/views/Config/CameraManagementConfig.vue')
                      },
                      {
                        path: 'lane/:laneNumber',
                        props: true,
                        component: () => import('@/views/Config/CameraManagementConfig.vue')
                      }
                    ]
                  },
                  {
                    path: 'startfinish',
                    props: true,
                    component: () => import('@/components/Base/ParentComponent.vue'),
                    meta: {
                      breadcrumb: `Start / Finish`
                    },
                    children: [
                      {
                        path: '',
                        props: true,
                        component: () => import('@/views/Config/StartFinishConfig.vue')
                      },
                      {
                        path: 'lane/:laneNumber',
                        props: true,
                        component: () => import('@/views/Config/StartFinishConfig.vue')
                      }
                    ]
                  },
                  {
                    path: 'videodirector',
                    props: true,
                    component: () => import('@/components/Base/ParentComponent.vue'),
                    meta: {
                      breadcrumb: `Video Director`
                    },
                    children: [
                      {
                        path: '',
                        props: true,
                        component: () => import('@/views/Config/VideoDirector/VideoDirectorConfigEdit.vue'),
                      },
                      {
                        path: ':configId',
                        props: true,
                        component: () => import('@/views/Config/VideoDirector/VideoDirectorConfigRecorderEdit.vue'),
                        meta: {
                          breadcrumb: routeParams => `Video Recorder ${ routeParams.configId }`
                        }
                      },
                      {
                        path: 'lane/:laneNumber',
                        props: true,
                        component: () => import('@/components/Base/ParentComponent.vue'),
                        children: [
                          {
                            path: '',
                            props: true,
                            component: () => import('@/views/Config/VideoDirector/VideoDirectorConfigEdit.vue'),
                          },
                          {
                            path: 'recorder/:configId',
                            props: true,
                            component: () => import('@/views/Config/VideoDirector/VideoDirectorConfigRecorderEdit.vue'),
                            meta: {
                              breadcrumb: routeParams => `Video Recorder ${ routeParams.configId }`
                            }
                          }
                        ]
                      },
                      
                    ]
                  }
                ]
              },
              {
                path: 'startfinish',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Start / Finish`
                },
                children: [
                  {
                    path: '',
                    props: true,
                    component: () => import('@/views/StartFinishDetailsView.vue')
                  },
                  {
                    path: 'lane/:laneNumber',
                    props: true,
                    component: () => import('@/views/StartFinishDetailsView.vue'),
                  }
                ]
              },
              {
                path: 'cameramanagement',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Camera Management Details`
                },
                children: [
                  {
                    path: '',
                    props: true,
                    component: () => import('@/views/CameraManagementView.vue')
                  },
                  {
                    path: 'lane/:laneNumber',
                    props: true,
                    component: () => import('@/views/CameraManagementView.vue')
                  }
                ]
              },
              {
                path: 'videodirector',
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Video Director`
                },
                children: [
                  {
                    path: '',
                    redirect: { path: '/installation/:installationId/tracker' },
                  },
                  {
                    path: ':videoRecorderId',
                    props: true,
                    component: () => import('@/views/VideoDirectorView.vue'),
                    meta: {
                      breadcrumb: routeParams => `Video Recorder ${ routeParams.videoRecorderId }`
                    },
                  },
                  {
                    path: 'lane/:laneNumber/recorder/:videoRecorderId',
                    props: true,
                    component: () => import('@/views/VideoDirectorView.vue'),
                    meta: {
                      breadcrumb: routeParams => `Video Recorder ${ routeParams.videoRecorderId }`
                    },
                  }
                ]
              },
            ]
          },
          {
            path: 'resources',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Resources.vue'),
            meta: {
              breadcrumb: `Resources`
            }
          },
          {
            path: 'reports',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Reports.vue'),
            meta: {
              breadcrumb: `Reports`
            }
          },
          {
            path: 'phototrap',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Phototrap.vue'),
            meta: {
              breadcrumb: `Phototrap`
            }
          },
          {
            path: 'administration',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_LoginData.vue'),
            meta: {
              breadcrumb: `Administration`
            }
          },
          {
            path: 'photopoint',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Photopoint.vue'),
            meta: {
              breadcrumb: `Photopoint`
            }
          },
          {
            path: 'svg-template',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Template.vue'),
            meta: {
              breadcrumb: `SVG Template`
            }
          },
          {
            path: 'actioncam',
            meta: {
              breadcrumb: `Actioncam`
            },
            component: () => import('@/components/Base/ParentComponent.vue'),
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_Actioncam.vue'),
              },
              {
                path: ':deviceId',
                props: true,
                component: () => import('@/views/Actioncam/ActioncamConfigurationView.vue'),
                meta: {
                  breadcrumb: routeParams => routeParams.deviceId
                }
              }
            ]
          },
          {
            path: 'notificationSwitzerland',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_WorkNotification.vue'),
            meta: {
              breadcrumb: `Work Notification`
            }
          },
          {
            path: 'terminalSales',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_TerminalSales.vue'),
            meta: {
              breadcrumb: `Sales Transactions`
            }
          },
          {
            path: 'terminal-status',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_TerminalStatus.vue'),
            meta: {
              breadcrumb: `Status`
            }
          },
          {
            path: 'terminal-tool',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_TerminalTool.vue'),
            meta: {
              breadcrumb: `Terminal`
            }
          },
          {
            path: 'converterConfigs',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_ConverterConfigs.vue'),
            meta: {
              breadcrumb: `Converter Configs`
            }
          },
          {
            path: 'converterSections',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_ConverterSections.vue'),
            meta: {
              breadcrumb: `Sections`
            }
          },
          {
            path: 'converterRoutingRules',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_ConverterRoutingRules.vue'),
            meta: {
              breadcrumb: `Routing Rules`
            }
          },
          {
            path: 'panocam-image',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_PanocamImage.vue'),
            meta: {
              breadcrumb: `Image`
            }
          },
          {
            path: 'software-components',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_SoftwareComponent.vue'),
            meta: {
              breadcrumb: `Software Components`
            },
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_SoftwareComponent.vue'),
              },
              {
                path: ':selectedId',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_SoftwareComponent.vue'),
                meta: {
                  breadcrumb: routeParams => routeParams.selectedId
                }
              }
            ]
          },
          {
            path: 'order-error-logs',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_OrderErrorLogs.vue'),
            meta: {
              breadcrumb: `Order Error Logs`
            }
          },
          {
            path: 'peak-login/:username',
            props: true,
            component: () => import('@/views/SoftwareComponent/PeakLoginRedirectView.vue'),
            meta: {
              breadcrumb: `Peak Login`
            }
          },
          {
            path: 'sla',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Sla.vue'),
            meta: {
              breadcrumb: `SLA`
            }
          },
          {
            path: 'webhooks',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Webhooks.vue'),
            meta: {
              breadcrumb: `Relations`
            }
          },
          {
            path: 'installation-relations',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_InstallationRelations.vue'),
            meta: {
              breadcrumb: `Relations`
            }
          },
          {
            path: 'installation-contacts',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_InstallationContacts.vue'),
            meta: {
              breadcrumb: `Contacts`
            }
          },
          {
            path: 'destination-os-emma-dashboard',
            props: true,
            component: () => import('@/components/Base/ParentComponent.vue'),
            meta: {
              breadcrumb: `Dashboard`
            },
            children: [
              {
                path: '',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_DestinationDashboard.vue'),
                meta: {
                  breadcrumb: 'Dashboard'
                }
              },
              {
                path: ':graphId/:hostId',
                props: true,
                component: () => import('@/views/Installation/InstallationDetail_DestinationDashboardSingle.vue'),
                meta: {
                  breadcrumb: routeParams => routeParams.graphId
                }
              }
            ]
          },
          {
            path: 'event',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Event.vue'),
            meta: {
              breadcrumb: `Event`
            }
          },
          {
            path: 'changelog',
            props: true,
            component: () => import('@/views/Installation/InstallationDetail_Changelog.vue'),
            meta: {
              breadcrumb: `Changelog`
            }
          }
        ]
      }
    ]
  }
];